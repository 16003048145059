import { useValidatedForm } from '~/providers/form-provider'
import Button, { ButtonProps } from './ui/button'
import { useContext } from 'react'

export type SubmitButtonProps = {
	label?: string
	submittingLabel?: string
	disableWhenInvalid?: boolean
	name?: string
	value?: string
	disabled?: boolean
	hug?: boolean
	disableOnSuccess?: boolean
}

export default function SubmitButton({
	label = 'Submit',
	submittingLabel = 'Submitting...',
	disableWhenInvalid,
	value,
	disabled,
	hug = false,
	disableOnSuccess
}: SubmitButtonProps) {
	const { isSubmitting, errors, id, resultsSuccess } = useValidatedForm()
	const isValid = Object.keys(errors).length === 0
	const isDisabled = (disableWhenInvalid
		? isSubmitting || !isValid
		: isSubmitting) || disabled || (disableOnSuccess && resultsSuccess)
	return (
		<Button
			disabled={isDisabled}
			$style='primary'
			$size='medium'
			fullWidth={!hug}
			attributes={{
				type: 'submit',
				id: 'submit-' + id,
				value
			}}
		>
			{isSubmitting ? submittingLabel : label}
		</Button>
	)
}
